import self from "../img/self.png"; // Self-portrait image
import mock1 from "../img/mock1.png"; // Project image

/* 
Hi there! Thanks for checking out my portfolio! 
This template showcases my skills, experiences, and projects. 
Feel free to explore and reach out if you have any questions!
*/

// Color palette for the site
export let colors = ["rgb(212,203,185)", "rgb(0,0,0)"];

// Switch to single-page layout if desired
export let singlePage = false;

// Portfolio information
export const info = {
    firstName: "Mohamed",
    lastName: "Ben Rezeg",
    initials: "js", // Initials for branding
    position: "Full Stack Developer / Network Engineer & Web Graphic Designer",
    selfPortrait: self, // Path to your self-portrait image
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // Gradient for the background
    baseColor: colors[0],
    miniBio: [
        {
            emoji: '☕',
            text: 'Fueled by coffee'
        },
        {
            emoji: '🇹🇳',
            text: 'Based in Tunisia'
        },
        {
            emoji: "💼",
            text: "CEO of Codify",
            link: "https://codify.tn" // Link to your company
        },
        {
            emoji: "📧",
            text: "contact@ben-rezeg.tn"
        }
    ],
    socials: [
        {
            link: "https://drive.google.com/file/d/1tgtntR6nibRVqTPBI2ItEWkR3YswDOeo/view?usp=sharing",
            icon: "fa fa-file-text",
            label: 'CV'
        },
        {
            link: "https://facebook.com/mohamed.benrezeg.39/",
            icon: 'fa fa-facebook',
            label: 'Facebook'
        },
        {
            link: "https://instagram.com",
            icon: 'fa fa-instagram',
            label: 'Instagram'
        },
        {
            link: "https://github.com/CodifyDevelopement/CodifyDevelopement",
            icon: "fa fa-github",
            label: 'GitHub'
        },
        {
            link: "https://www.linkedin.com/in/mohamed-ben-rezeg-b85a1b307?originalSubdomain=tn",
            icon: "fa fa-linkedin",
            label: 'LinkedIn'
        }
    ],
    bio: "Hello! I'm Mohamed Ben Rezeg, a Full Stack Developer, Network Engineer, and Graphic Designer. I am passionate about creating efficient and innovative solutions. Let's work together to bring your ideas to life!",
    skills: {
        proficientWith: ['JavaScript', 'React', 'MongoDB', 'Git', 'GitHub', 'Bootstrap', 'HTML5', 'CSS3', 'Figma'],
        exposedTo: ['Node.js', 'Python', 'Adobe Illustrator', 'Adobe Photoshop']
    },
    hobbies: [
        {
            label: 'Reading',
            emoji: '📖'
        },
        {
            label: 'Gaming',
            emoji: '🎮'
        },
        {
            label: 'Movies',
            emoji: '🎥'
        },
        {
            label: 'Coding',
            emoji: '🖥️' // Updated emoji for coding
        }
    ],
    portfolio: [
        {
            title: "Codify Digital Company",
            live: "https://codify.tn", // Link to your live project
            image: mock1 // Image for the project
        }
    ],
};

import React from 'react';
import './App.module.scss';
import BaseLayout from "./components/BaseLayout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; 
import Home from "./components/home/Home"; 
import About from "./components/about/About";
import Portfolio from "./components/portfolio/Portfolio"; 
import DesignPortfolio from "./components/design/DesignPortfolio"; 

function App() {
   return (
      <Router>
         <BaseLayout>
         <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/design" element={<DesignPortfolio />} /> {/* Correct route path */}
         </Routes>
         </BaseLayout>      
         </Router>
   );
}

export default App;

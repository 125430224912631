import React, { useEffect, useState } from 'react';
import Style from './BaseLayout.module.scss';
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import MultiPageRoutes from './MultiPageRoutes';
import { singlePage } from '../info/Info';
import SinglePageRoutes from './SinglePageRoutes';
import useScrollObserver from '../hooks/useScrollObserver';

export default function BaseLayout() {
   const location = useLocation();

   // Set active section based on the current pathname
   const [active, setActive] = useState(location.pathname === '/' ? 'home' : location.pathname.slice(1));
   const refHome = useScrollObserver(setActive);
   const refAbout = useScrollObserver(setActive);
   const refPortfolio = useScrollObserver(setActive);
   const refDesignPortfolio = useScrollObserver(setActive); // New reference for design portfolio
   const [darkMode, setDarkMode] = useState(false);

   // Toggle dark mode and save preference in local storage
   const handleToggleDarkMode = () => {
      const newDarkModeState = !darkMode;
      localStorage.setItem('darkMode', JSON.stringify(newDarkModeState));
      setDarkMode(newDarkModeState);
   };

   useEffect(() => {
      const detectedDarkMode = JSON.parse(localStorage.getItem('darkMode'));

      if (detectedDarkMode !== null) {
         setDarkMode(detectedDarkMode);
      } else {
         localStorage.setItem('darkMode', 'false');
      }
   }, []);

   return (
      <Box className={darkMode ? Style.dark : Style.light}>
         <Grid container display={'flex'} flexDirection={'column'} minHeight={'100vh'} justifyContent={'space-between'}>
            <Grid item>
               <Navbar 
                  darkMode={darkMode} 
                  handleClick={handleToggleDarkMode} 
                  active={active} 
                  setActive={setActive} 
               />
            </Grid>
            <Grid item flexGrow={1}>
               {singlePage ? (
                  <SinglePageRoutes refs={{ refHome, refAbout, refPortfolio, refDesignPortfolio }} /> // Include new reference here
               ) : (
                  <MultiPageRoutes />
               )}
            </Grid>
            <Grid item>
               <Box 
                  component={'footer'} 
                  display={'flex'} 
                  flexDirection={'column'} 
                  alignItems={'center'} 
                  py={'1.5rem'} 
                  sx={{ opacity: 0.7 }} 
                  width={'100%'}
               >
                  <p>
                     All Rights Reserved by <a href={'https://codify.tn'}>Codify </a>
                  </p>
                  <p>&copy; 2024</p> - MOHAMED BEN REZEG -
               </Box>
            </Grid>
         </Grid>
      </Box>
   );
}

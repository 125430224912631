import React, { useState } from 'react';
import { Box, Grid, Typography, Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Style from './DesignPortfolio.module.scss';

const designProjects = [
  {
    title: 'Octagon Brand',
    images: [
      'https://i.ibb.co/JpmC29y/d10189195604221-66141c5eabb1d.jpg',
      'https://i.ibb.co/HzzLZ97/4344119318964988-8587893797596537008-n.jpg',
      'https://i.ibb.co/Rj2nB8L/23ab12195604221-6610685ede969.jpg',
      'https://i.ibb.co/FqKPbxG/8e1c38195604221-6610444c8971b.jpg'
    ],
    description: 'This project involved designing a sleek, modern logo using the octagon to represent strength and versatility. We crafted a distinct brand identity by combining clean lines, unique typography, and carefully chosen colors, creating a memorable and professional look for the Octagon Brand.',
  },
  {
    title: 'Turbo Brand',
    images: [
      'https://i.ibb.co/yNNf5Dy/06ac12205951799-66c475a6cd9ed.jpg', 
      'https://i.ibb.co/frS9pc1/587297205951799-66c475a6cd146.jpg',
      'https://i.ibb.co/wyLbqRZ/7d949d205951799-66c475a6d25a2.jpg',
      'https://i.ibb.co/bFHhDz2/c464eb205951799-66c475a6cf3ed.jpg'
    ],
    description: 'Turbo Brand is built for those who love speed and performance. Our brand is all about energy and momentum, reflecting a bold, dynamic approach. We combine sharp, modern visuals with powerful design to create an identity that stands out, capturing the spirit of innovation and drive. Turbo Brand is for those who want to move fast and make a strong impact.',
  },
  {
    title: 'Xenstar',
    images: [
      'https://i.ibb.co/F6xcj30/9ae5f3189484445-65ac13904a2da.jpg', 
      'https://i.ibb.co/Wf7CqdH/99741a189484445-65ac139042c81.jpg',
      'https://i.ibb.co/zfypPxD/b4378f189484445-65ac154608cee.jpg',
      'https://i.ibb.co/GQg9q3C/333878189484445-65ac13904b5cb.jpg'
    ],
    description: 'As a freelance graphic designer for Xenstar, I collaborated with the team to create impactful visual content that embodies the agency’s innovative approach. My responsibilities included designing graphics for various projects, such as branding materials, digital assets, and promotional content. By understanding the unique requirements of each project, I developed creative solutions that effectively communicated the desired message and enhanced the overall user experience. This experience allowed me to contribute to Xenstar’s mission of inspiring creativity while building strong visual identities for their clients.',
  },
  {
    title: 'Hadlet Epicerie Fine',
    images: [
      'https://i.ibb.co/d5JBY7Z/voucher-Preview.png', 
      'https://i.ibb.co/SQj6KL8/real-flag.png',
      'https://i.ibb.co/kKpTDK5/gg.png',
      'https://i.ibb.co/b23jgNb/flag2.png',
      'https://i.ibb.co/NnGDQ4Q/CRUNCHY-MILK.png'
    ],
    description: 'In my role as a freelance graphic designer for an épicerie fine, I focused on creating elegant and inviting visual designs that reflect the essence of gourmet products. My work included designing packaging, promotional materials, and branding elements that enhanced the store’s identity and appealed to its discerning clientele. By closely collaborating with the owner, I ensured that my designs captured the rich flavors and artisanal qualities of the offerings, effectively conveying a sense of luxury and sophistication. This project allowed me to blend my passion for design with an appreciation for fine food, creating visuals that resonate with customers.',
  },  {
    title: 'Finito Finance Application',
    images: [
      'https://i.ibb.co/HzLxSfQ/de6248203830955-66a2a15927104.png',
      'https://i.ibb.co/9ysShn1/4682ab203830955-669e8143cc81c.png', 
      'https://i.ibb.co/d0d9W6Z/594ce2203830955-669e80073b323.png',
      'https://i.ibb.co/8cTQf2N/252177203830955-669e8143cc05a.png'

    ],
    description: 'As a freelance UI/UX designer for a finance secure agency, I focused on creating user-friendly and visually appealing interfaces for their mobile and web applications. My role involved conducting user research to understand client needs and pain points, followed by designing intuitive wireframes and prototypes that enhance the user experience. I collaborated closely with developers and stakeholders to ensure that the final designs not only met aesthetic standards but also prioritized security and functionality. This experience allowed me to apply my design skills in a critical industry while contributing to solutions that empower users to manage their finances confidently.',
  },
  // Add more design projects as needed
];


const DesignPortfolio = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  const openProject = (project) => {
    setSelectedProject(project);
  };

  const closeProject = () => {
    setSelectedProject(null);
  };

  return (
    <Box padding={2} className={Style.portfolioContainer}>
      <Typography variant="h4" gutterBottom className={Style.portfolioTitle}>
        My Design Portfolio
      </Typography>
      <Grid container spacing={4}>
        {designProjects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box className={Style.projectCard} onClick={() => openProject(project)}>
              <Box className={Style.imageContainer}>
                <img
                  src={project.images[0]}
                  alt={project.title}
                  className={Style.projectImage}
                  onContextMenu={(e) => e.preventDefault()} // Disable right-click
                />
              </Box>
              <Typography variant="h6" className={Style.projectTitle}>
                {project.title}
              </Typography>
              <Typography variant="body2" className={Style.projectDescription}>
                {project.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Modal for displaying project details */}
      {selectedProject && (
        <Dialog open={Boolean(selectedProject)} onClose={closeProject} fullScreen>
          <DialogContent className={Style.dialogContent}>
            <IconButton className={Style.closeButton} onClick={closeProject}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h4" gutterBottom>{selectedProject.title}</Typography>
            <Typography variant="body1" gutterBottom>{selectedProject.description}</Typography>
            
            {/* Full-screen image gallery */}
            <Box className={Style.dialogImages}>
              {selectedProject.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${selectedProject.title} ${index + 1}`}
                  className={Style.dialogImage}
                  onContextMenu={(e) => e.preventDefault()} // Disable right-click
                />
              ))}
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default DesignPortfolio;
